import React, { useEffect, useState } from 'react';
import { CodeResponse, Validation, generateCode, startVerifying } from '../../utils/nucleo';
import { AppContext } from '../App/App';
import { loading } from '../../actions';
import QR from '../../components/qr';
import styled from 'styled-components';
import { API_KEY } from '../../utils/constants';
import { useSearchParams } from 'react-router-dom';

interface DemoProps {
  lang: string,
  dispatch: React.Dispatch<any>,
}

interface CodeStorage extends CodeResponse {
  expires_at?: number,
  key?: string;
}

type DemoStorage = {
  code: CodeStorage,
  validation: Validation
}

const EXPIRES_IN = 10;

const translations: { [k: string]: any } = {
  es: {
    link: "Verifica tu número con el código y vuelve a la aplicación",
    openWa: "Abrir WhatsApp",
    tryTitle: "Pruebalo 🔮",
    tryDescription: "En 10 segundos vamos a adivinar tu teléfono. Solo escanea el QR o apreta el boton de WhatsApp.",
    welcome: "Bienvenido a WhatsAuth",
    yourPhone: "Tu numero es",
    welcomeDescription: "Era rapido, cierto? Puedes tener esta mágia tu mismo, solo regístrate o contacta con nosotros.",
    description: "La forma más sencilla de validar a tus clientes y usuarios. Prueba nuestro flujo de autenticación por WhatsApp o SMS y luego contáctanos para escuchar tu feedback.",
    letsTalk: "Sign Up",
    tryWa: "Probar con WhatsApp",
    trySms: "Probar con SMS",
    hi: "Hola",
    ypni: "tu numero de teléfono es",
    right: "cierto",
    successMsg: "Tu numero ha sido validado exitosamente. Regresa a la app",
    contactUs: "Contacta con nosotros",
    tryAgain: "Probar de nuevo",
  },
  en: {
    link: "Verify your phone number with the code and return to app",
    openWa: "Open WhatsApp",
    tryTitle: "Try it out 🔮",
    welcome: "Welcome 🎉",
    yourPhone: "Your phone is",
    tryDescription: "Give us 10 seconds to guess your phone number. Just scan the QR or click whatsapp button.",
    welcomeDescription: "That was fast, wasn't it? Use this magic for your own use cases by creating your account or by contacting us.",
    letsTalk: "Sign Up",
    tryWa: "Try with WhatsApp.",
    trySms: "Try with SMS.",
    hi: "Hi",
    ypni: "your phone number is",
    right: "right",
    successMsg: "Your phone number was successfully verified. Go back to the app",
    contactUs: "Contact us",
    tryAgain: "Try again",
  }
};

const Demo: React.FC<DemoProps> = ({ dispatch, lang }: DemoProps): JSX.Element => {
  const tr = translations[lang] ? translations[lang] : translations["en"]
  const [code, setCode] = useState<CodeStorage | null>(null);
  const [validation, setValidation] = useState<Validation | null>(null);
  const [qString] = useSearchParams();
  const apiKey = qString.get("apikey");

  useEffect(() => {
    const validation = readValidation();
    setValidation(validation);

    if (!validation) {
      startDemo();
    }
    // eslint-disable-next-line
  }, []);

  const onValidationReceive = (validation: Validation): void => {
    if (validation.status === "expired") {
      getCode(setCode, dispatch, onValidationReceive, { apiKey });
      setValidation(null);
      return;
    }

    setValidation(validation);
    saveValidation(validation);

    // if (validation.phone_number) {
    //   window.gtag('event', 'conversion', { 'send_to': 'AW-10961875262/OlpjCML53dEDEL7ig-so', phone_number: validation.phone_number })
    // }

    setCode(null);
    saveCode(null);
  }

  const startDemo = () => {
    setValidation(null);
    saveValidation(null);
    getCode(setCode, dispatch, onValidationReceive, { apiKey });
  }

  const renderCode = (code: CodeResponse | null) => {
    if (code) {
      return (
        <div id="demo">
          <div className='demo-content'>
            <div className='demo-block'>
              <Response className='validation-request'>
                <QR url={code?.qr || ''} />
                <Button className="mobile-big" href={whatsappLink(code)} target='_blank' rel='noreferrer'>Send WhatsApp</Button>
              </Response>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="demo">
          <div className='demo-content'>
            {validation && <div className='demo-block'>
              <Response className='validation-request'>
                {renderValidation(validation)}
              </Response>
              <Link onClick={startDemo}>{tr.tryAgain}</Link>
            </div>}
          </div>
        </div>
      );
    }
  }

  const renderValidation = (validation: Validation) => {
    return (
      <div className='json-snippet'>
        <pre>
          <code className="lang-json">
            <Comment>{"// JSON response"}</Comment>
            <p>{"{"}</p>
            <p className='row'><span className='json-key'>"code"</span>: <span className='json-string'>"{validation.verification_code}"</span>,</p>
            <p className='row'><span className='json-key'>"status"</span>: <span className='json-string'>"{validation.status}"</span>,</p>
            <p className='row'><span className='json-key'>"phone_number"</span>: <span className='json-string phone'>"{validation.phone_number}"</span>,</p>
            <p className='row'><span className='json-key'>"profile_name"</span>: <span className='json-string'>"{validation.profile_name}"</span></p>
            <p>{"}"}</p>
          </code>
        </pre>
      </div >
    );
  };

  const renderMainText = () => {
    if (validation) {
      return (
        <div id="welcome">
          <h1>{tr.welcome}, {validation.profile_name}! {tr.yourPhone} {validation.phone_number} 😉</h1>
          <p>{tr.welcomeDescription}</p>
          <Button href="https://app.whatsauth.com" target="_blanc">{tr.letsTalk}</Button>
          <Button href="https://www.whatsauth.com/contact" target="_blanc">{tr.contactUs}</Button>
        </div>
      )
    } else {
      return (
        <div id="welcome">
          <h1>{tr.tryTitle}</h1>
          <p>{tr.tryDescription}</p>
        </div>
      )
    }
  }

  return (
    <div className='page-container'>
      <div>
        {renderMainText()}
      </div>

      {renderCode(code)}
    </div>
  );
};

const getCode = async (setCodeFn: React.Dispatch<any>, dispatch: React.Dispatch<any>, validationCb: (v: Validation) => void, opts: { [key: string]: unknown }): Promise<void> => {
  dispatch(loading(true));
  const storedCode: CodeStorage | null = readCode();
  const apiKey = (opts.apiKey as string) || API_KEY;

  if (storedCode && storedCode.key && storedCode.key === apiKey && storedCode.expires_at && storedCode.expires_at > now()) {
    setCodeFn(storedCode)
    startVerifying(apiKey, storedCode.code, validationCb);
    dispatch(loading(false));
  } else {
    generateCode(apiKey, { qr: "1", expires_at: EXPIRES_IN })
      .then((response) => {
        setCodeFn(response);
        saveCode(response, apiKey);
        startVerifying(apiKey, response.code, validationCb);
        dispatch(loading(false));
      })
      .catch((error) => {
        console.error('Error:', error)
        dispatch(loading(false));
      });
  }
};

const calculateExpiration = (): number => {
  return now() + (EXPIRES_IN * 60 * 1_000);
}

const now = (): number => {
  return Date.parse((new Date()).toISOString());
}

const readCode = (): CodeStorage | null => {
  const demo: DemoStorage = JSON.parse(localStorage.getItem('demo') as string);
  return demo ? demo.code : null;
}

const readValidation = (): Validation | null => {
  const demo: DemoStorage = JSON.parse(localStorage.getItem('demo') as string);
  return demo ? demo.validation : null;
}

const saveCode = (code: CodeResponse | null, key?: string): void => {
  const demo: DemoStorage = JSON.parse(localStorage.getItem('demo') as string);
  const codeToStore: CodeStorage | null = code ? { ...code, expires_at: calculateExpiration(), key: key } : null;
  localStorage.setItem("demo", JSON.stringify({ ...demo, code: codeToStore }));
}

const saveValidation = (validation: Validation | null): void => {
  const demo: DemoStorage = JSON.parse(localStorage.getItem('demo') as string);
  localStorage.setItem("demo", JSON.stringify({ ...demo, validation: validation }));
}

const whatsappLink = (code: CodeStorage): string => {
  const mobile = window.navigator.userAgent.includes("Mobile") || window.navigator.userAgent.includes("Android") || window.navigator.userAgent.includes("iPhone");
  return mobile && code.deep_link ? code.deep_link : code.link;
}

const HomePage: React.FC = () => {
  return (
    <AppContext.Consumer>
      {({ dispatch }) => (
        <Demo dispatch={dispatch} lang={"en"} />
      )}
    </AppContext.Consumer>
  );
};

const Response = styled.div`
  margin-top: 40px;
`

const Button = styled.a`
  background: #6239ec;
  cursor: pointer;
  color: white;
  padding: 20px;
  margin: 0 20px;
  border-radius: 8px;
  display: inline-block;
  text-decoration: none;

  &:hover {
    transform: scale3d(1.05, 1.05, 1);
  }

  &.mobile-big {
    @media (max-width: 600px) {
      margin: 0;
      width: 100%;
    }
  }
`

const Link = styled.a`
  color: white;
  cursor: pointer;
  display: inline-block;
  margin-top: 30px;

  &:hover {
    text-decoration: underline;
    transform: scale3d(1.05, 1.05, 1);
  }

  @media (max-width: 600px) {
    background: #6239ec;
    padding: 20px;
    margin: 20px 0 0 0;
    border-radius: 8px;
    width: 100%;
  }
`

const Comment = styled.p`
  color: grey;
`

export default HomePage;