import React from 'react';

interface FlashProps {
  text: string;
  type: string;
  onClose: React.MouseEventHandler;
}

const Flash: React.FC<FlashProps> = (props: FlashProps) => {
  return (
    <div className={`flash ${props.type}`}>
      <p>{props.text}</p>
      <span onClick={props.onClose}>x</span>
    </div>
  );
};

export default Flash;