import React from "react";
import logo from '../../assets/logo.png';
import { User } from "../../types";
import { useContext } from 'react';
import { AppContext } from "../../containers/App/App";
import { logout } from "../../actions";

interface HeaderProps {
  user?: User | null;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { dispatch } = useContext(AppContext);

  const renderUser = (user: User) => {
    return (
      <div className="user-corner">
        <img src={user.picture} alt="avatar" />
        <span>{user.given_name} {user.family_name}</span>
        <div className="drop-down">
          <span onClick={logoutMe}>Logout</span>
        </div>
      </div>
    );
  };

  const logoutMe = () => {
    dispatch(logout());
  };

  return (
    <header>
      <a href="https://whatsauth.com"><img src={logo} alt="logo" /></a>
      {props.user && renderUser(props.user)}
    </header>
  );
};

export default Header;
