import React from 'react';
import { AppContext } from '../App/App';
import jwt_decode from "jwt-decode";
import { useEffect } from 'react';
import { CodeResponse, generateCode } from '../../utils/nucleo';
import { useState } from 'react';
import QR from '../../components/qr';
import { wsConnect } from '../../utils/webSockets';
import { Link } from '../../styles';
import { loading } from '../../actions';
import { Auth } from '../../reducers/App';
import { authenticate, refreshToken, user } from '../../utils/auth';
import { useLocation, useParams } from 'react-router-dom';
import { WEB_CLIENT_URL } from '../../utils/constants';

type UserBase = {
  partner: string;
}

declare global {
  interface Window { gtag: any; }
}

interface User extends UserBase {
  [key: string]: any
}

type Validation = {
  verification_code: string;
  phone_number?: string;
  status: string;
  requested_at: string;
  validated_at?: string;
  expires_at: string;
  partner: string;
  expiration: number;
  callback_url?: string;
  error?: string;
  link_message?: string;
  response_message?: string;
  expiration_message?: string;
  failure_message?: string;
  messages_count?: number;
  created_at?: number;
  profile_name?: string;
}

interface DemoProps {
  auth: Auth,
  dispatch: React.Dispatch<any>,
  demoType?: string,
  authorizedPhone?: string,
  lang: string,
}

interface DemoPageProps {
  demoType?: string,
  authorizedPhone?: string,
  lang?: string,
}

const translations: { [k: string]: any } = {
  es: {
    link: "Verifica tu número con el código y vuelve a la aplicación",
    openWa: "Abrir WhatsApp",
    welcome: "Bienvenido a WhatsAuth",
    description: "La forma más sencilla de validar a tus clientes y usuarios. Prueba nuestro flujo de autenticación por WhatsApp o SMS y luego contáctanos para escuchar tu feedback.",
    letsTalk: "Sign Up",
    tryWa: "Probar con WhatsApp",
    trySms: "Probar con SMS",
    hi: "Hola",
    ypni: "tu numero de teléfono es",
    right: "cierto",
    successMsg: "Tu numero ha sido validado exitosamente. Regresa a la app",
  },
  en: {
    link: "Verify your phone number with the code and return to app",
    openWa: "Open WhatsApp",
    welcome: "Welcome to WhatsAuth",
    description: "The easiest way to validate your customers and users. Try our authentication flow by WhatsApp or SMS and then contact us to hear your feedback.",
    letsTalk: "Sign Up",
    tryWa: "Try with WhatsApp.",
    trySms: "Try with SMS.",
    hi: "Hi",
    ypni: "your phone number is",
    right: "right",
    successMsg: "Your phone number was successfully verified. Go back to the app",
  }
}

const Demo: React.FC<DemoProps> = ({ auth, dispatch, demoType, authorizedPhone, lang }: DemoProps) => {
  const [code, setCode] = useState<CodeResponse | null>(null);
  const [validation, setValidation] = useState<Validation | null>(null);
  const [creds, setCreds] = useState<Auth | null>(null);

  const location = useLocation();

  const tr = translations[lang] ? translations[lang] : translations["es"]

  const linkMessage = `${tr.link} ${WEB_CLIENT_URL}${location.pathname}.`;

  useEffect(() => {
    login(dispatch, setCreds);
    // eslint-disable-next-line
  }, []);

  // Connect to WS on receive a verification code.
  useEffect(() => {
    if (!code?.code || !creds?.access_token) { return }

    wsConnect(creds.access_token, code.code, onValidationReceive);

  }, [code?.code, creds?.access_token]);

  // Recover demo information from session storage on mount.
  useEffect(() => {
    const demo = JSON.parse(localStorage.getItem('demo') as string);
    setValidation(demo);
  }, []);

  const onValidationReceive = (validation: Validation) => {
    setValidation(validation);
    localStorage.setItem('demo', JSON.stringify(validation));

    // if (validation.phone_number) {
    //   window.gtag('event', 'conversion', { 'send_to': 'AW-10961875262/OlpjCML53dEDEL7ig-so', phone_number: validation.phone_number })
    // }

    setCode(null);
  }

  const startDemo = (opts: { [k: string]: string | undefined }) => {
    if (!creds?.access_token) {
      return;
    };

    let params;

    switch (demoType) {
      case "qr":
        params = { ...opts, qr: '1' };
        break;
      case "otp":
        params = { ...opts, qr: '1', authorized_numbers: [authorizedPhone] };
        break;
      default:
        params = {
          ...opts, qr: '1'
        };
        break;
    }

    dispatch(loading(true));

    const { partner: apiKey } = jwt_decode(creds.access_token as string) as User;

    generateCode(apiKey, params)
      .then((response) => {
        setValidation(null);
        setCode(response);
        dispatch(loading(false));
      })
      .catch((error) => {
        console.error('Error:', error)
        dispatch(loading(false));
      });
  };

  const clearDemo = () => {
    setCode(null);
    setValidation(null);
    localStorage.setItem('demo', JSON.stringify(null));
  }

  const renderDefaultDemo = (type: string | undefined) => {
    return (
      <div id="demo">
        <div className='demo-content'>
          <div className='demo-block'>
            <p id="demo-text">{tr.hi}, <span>{validation?.profile_name ? `${validation?.profile_name},` : ''}</span> {tr.ypni} <span>{validation?.phone_number || '______'}</span>, {tr.right}?</p>
          </div>
          <div className='demo-block'>
            {validation ? (
              <div className='validation-response'>
                {validation ? renderValidation(validation) : ''}
              </div>
            ) : (
              renderCode(code, type)
            )}
          </div>
        </div>
        <div>
          <button id="web-start-demo-wa" onClick={() => { startDemo({ channel: "whatsapp", response_message: undefined, link_message: linkMessage }) }} className="primary">{tr.tryWa}</button>
        </div>
        <div>
          <button id="web-start-demo-sms" onClick={() => { startDemo({ channel: "sms", response_message: undefined, link_message: linkMessage }) }} className="">{tr.trySms}</button>
        </div>
      </div>
    );
  }

  const renderOTPValidation = (validation: Validation) => {
    return (
      <div>
        {validation.status === "validated" ? (
          <div className='transaction tr-success'>
            Transacción #{validation.verification_code} ha sido autorizada exitosamente por el numero {validation.phone_number}.
          </div>
        ) : (
          <div className='transaction tr-failure'>
            Transacción #{validation.verification_code} ha sido rechazada.
          </div>
        )}

        <div>
          <button id="web-otp-demo" onClick={clearDemo} className="">Otra transacción</button>
        </div>
      </div>
    );
  };

  const renderOTPDemo = (type: string) => {
    return (
      <div id="demo">
        <div className='demo-content'>
          <div className='demo-block'>
            {code &&
              (
                <div className='validation-request'>
                  <QR url={code?.qr || ''} />
                  <Link href={code?.link} target='_blank' rel='noreferrer'>{tr.openWa}</Link>
                </div>
              )
            }

            {validation && (
              renderOTPValidation(validation)
            )}

            {!code && !validation && (
              <div>
                <button id="web-otp-demo" onClick={() => { startDemo({ channel: "whatsapp" }) }} className="">Autorizar transacción</button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderDemo = (type: string | undefined) => {
    if (type === "otp") {
      return renderOTPDemo(type);
    } else {
      return renderDefaultDemo(type);
    }
  };

  const renderCode = (code: CodeResponse | null, type: string | undefined) => {
    if (code) {
      return (
        <div className='validation-request'>
          {(!type || type === "qr") && <QR url={code?.qr || ''} />}
          {((!type || type === "btn") && code?.link.includes("wa.me")) && <Link href={code?.link} target='_blank' rel='noreferrer'>Send WhatsApp</Link>}
          {((!type || type === "btn") && code?.link.includes("sms")) && <Link href={code?.link} target='_blank' rel='noreferrer'>Send SMS</Link>}
        </div>
      );
    } else {
      return (
        <div className='validation-response'>
          {renderValidation({} as Validation)}
        </div>
      );
    }
  }

  const renderValidation = (validation: Validation) => {
    return (
      <div className='json-snippet'>
        <pre>
          <code className="lang-json">
            <p>{"{"}</p>
            <p className='row'><span className='json-key'>"code"</span>: <span className='json-string'>"{validation.verification_code}"</span>,</p>
            <p className='row'><span className='json-key'>"status"</span>: <span className='json-string'>"{validation.status}"</span>,</p>
            <p className='row'><span className='json-key'>"phone_number"</span>: <span className='json-string phone'>"{validation.phone_number}"</span>,</p>
            <p className='row'><span className='json-key'>"profile_name"</span>: <span className='json-string'>"{validation.profile_name}"</span></p>
            <p>{"}"}</p>
          </code>
        </pre>
      </div >
    );
  };

  return (
    <div className='page-container'>
      <div>
        <div id="welcome">
          <h1>{tr.welcome}</h1>
          <p>{tr.description}</p>
          <a href="https://app.whatsauth.com" target="_blanc">{tr.letsTalk}</a>
        </div>
        <div id="video">
        </div>
      </div>

      {renderDemo(demoType)}
    </div>
  );
};

const login = (dispatch: React.Dispatch<any>, setCredsFn: React.Dispatch<any>): void => {
  dispatch(loading(true));

  authenticate(user.userName, user.password)
    .then((auth) => {
      setCredsFn(auth);
      subscribetoRefreshToken(auth?.refresh_token, dispatch, setCredsFn);
      dispatch(loading(false));
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(loading(false));
    });
};

const subscribetoRefreshToken = (token: string | undefined, dispatch: React.Dispatch<any>, setCredsFn: React.Dispatch<any>): void => {
  if (!token) { return }

  window.setTimeout(() => {
    refreshAccessToken(token, dispatch, setCredsFn);
  }, 300_000);
}

const refreshAccessToken = (token: string, dispatch: React.Dispatch<any>, setCredsFn: React.Dispatch<any>): void => {
  dispatch(loading(true));

  refreshToken(token)
    .then((auth) => {
      setCredsFn(auth);
      subscribetoRefreshToken(auth?.refresh_token, dispatch, setCredsFn);
      dispatch(loading(false));
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(loading(false));
    });
}

const DemoPage: React.FC<DemoPageProps> = ({ demoType }) => {
  const { phone: authorizedPhone, lang } = useParams();

  return (
    <AppContext.Consumer>
      {({ auth, dispatch }) => (
        <Demo auth={auth} dispatch={dispatch} demoType={demoType} authorizedPhone={authorizedPhone} lang={lang || "es"} />
      )}
    </AppContext.Consumer>
  );
};

export default DemoPage;
