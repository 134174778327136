import './App.css';
import RouteSeparator from '../RouteSeparator';
import React, { useEffect, useReducer, useState } from 'react';
import appReducer, { Auth, defaultState } from '../../reducers/App';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from '../../styles/global';
import Header from '../../components/header';
import Loader from '../../components/loader';
import { refreshToken, TokenResponse } from '../../utils/auth';
import { hideFlash, logout, successfullLogin } from '../../actions';
import { User } from '../../types';
import { buildUserFromToken } from '../../utils/helpers';
import Flash from '../../components/flash';

interface AppContextInterface {
  auth: Auth,
  user?: User | null,
  dispatch: React.Dispatch<any> | ((arg: any) => void)
}

const tokens = JSON.parse(localStorage.getItem('app-info') as string) || defaultState.auth;

const initialState = {
  ...defaultState,
  auth: tokens,
  user: tokens.access_token ? buildUserFromToken(tokens.access_token) : defaultState.user,
  dispatch: (arg: any) => { }
};

export const AppContext = React.createContext<AppContextInterface>(initialState);

function App() {
  const [appState, dispatch] = useReducer(appReducer, initialState);
  const [to, setTO] = useState<number | null>(null);

  useEffect(() => {
    refreshAccessToken(appState.auth.refresh_token, dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    localStorage.setItem('app-info', JSON.stringify(appState.auth));
  }, [appState.auth]);

  useEffect(() => {
    subscribetoRefreshToken(appState.auth.refresh_token, dispatch, setTO)
  }, [appState.auth.access_token, appState.auth.refresh_token]);

  useEffect(() => {
    if (!appState.auth.loggedIn && to) {
      window.clearTimeout(to);
      setTO(null);
    }
  }, [appState.auth.loggedIn, to]);

  const auth: AppContextInterface = {
    auth: appState.auth,
    user: appState.user,
    dispatch: dispatch,
  };

  const closeFlash = () => {
    dispatch(hideFlash());
  };

  return (
    <div>
      <GlobalStyle />
      <BrowserRouter>
        <AppContext.Provider value={auth}>
          <Header user={auth.user} />
          {appState.isLoading && <Loader />}
          {appState.flash && <Flash text={appState.flash.text} type={appState.flash.type} onClose={closeFlash} />}
          <RouteSeparator />
        </AppContext.Provider>
      </BrowserRouter>
    </div>
  );
}

const subscribetoRefreshToken = (token: string | undefined, dispatch: React.Dispatch<any>, toFn: React.Dispatch<any>): void => {
  if (!token) { return }

  const to = window.setTimeout(() => {
    refreshAccessToken(token, dispatch);
  }, 300_000);

  toFn(to);
};

const refreshAccessToken = (token: string | undefined, dispatch: React.Dispatch<any>): void => {
  if (!token) { return }

  refreshToken(token)
    .then((auth) => {
      dispatch(successfullLogin(auth as TokenResponse));
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(logout());
    });
};

export default App;
