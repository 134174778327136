import React from 'react';

interface QRProps {
  url: string;
}

const QR: React.FC<QRProps> = (props: QRProps) => {
  return (
    <div className='qr'>
      <img src={props.url} alt='QR' />
    </div>
  );
};

export default QR;
