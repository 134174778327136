import { NUCLEO_API_URL } from "../constants";

export type CodeResponse = {
  code: string;
  link: string;
  qr: string;
  deep_link?: string;
}

export type CodeRequest = {
  phone_number?: string;
  expires_at?: number;
  callback_url?: string;
  link_message?: string;
  response_message?: string;
  expiration_message?: string;
  failure_message?: string;
  qr?: string;
};

export type Validation = {
  verification_code: string;
  phone_number?: string;
  status: string;
  requested_at: string;
  validated_at?: string;
  expires_at: string;
  partner: string;
  expiration: number;
  callback_url?: string;
  error?: string;
  link_message?: string;
  response_message?: string;
  expiration_message?: string;
  failure_message?: string;
  messages_count?: number;
  created_at?: number;
  profile_name?: string;
}

export const generateCode = async (apiKey: string, options: { [key: string]: unknown }): Promise<CodeResponse> => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${apiKey}`);

  const baseUrl = `${NUCLEO_API_URL}/api/v1/verification_code`;
  const url = buildUrl(baseUrl, options);

  const response = await fetch(url, {
    method: 'GET',
    headers: headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });

  return await response.json();
}

export const startVerifying = async (apiKey: string, code: string, callback: (val: Validation) => void): Promise<void> => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${apiKey}`);

  const url = `${NUCLEO_API_URL}/api/v1/verify?code=${code}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });

  const validation = await response.json();

  if (validation.status !== "requested") {
    callback(validation);
  } else {
    setTimeout(async () => { await startVerifying(apiKey, code, callback) }, 1_000);
  }
};

function buildUrl(baseUrl: string, params: CodeRequest): string {
  const queryString = Object.keys(params).map((key) => {
    const value = params[key as keyof CodeRequest];
    return `${encodeURIComponent(key)}=${encodeURIComponent(value?.toString() || '')}`
  }).join('&');

  return `${baseUrl}?${queryString}`;
}