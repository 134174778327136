import React, { useEffect, useState } from 'react';
import { loading, showFlash, successfullLogin } from '../../actions';
import { Auth } from '../../reducers/App';
import { authenticate, refreshToken, TokenResponse, user } from '../../utils/auth';
import { AppContext } from '../App/App';
import jwt_decode from "jwt-decode";
import { CodeResponse, generateCode } from '../../utils/nucleo';
import QR from '../../components/qr';
import { NUCLEO_API_URL } from '../../utils/constants';
import { wsConnect } from '../../utils/webSockets';
import { Link } from '../../styles';
import { useNavigate } from "react-router-dom";

interface LoginProps {
  auth: Auth,
  dispatch: React.Dispatch<any>
}

type UserBase = {
  partner: string;
}

interface User extends UserBase {
  [key: string]: any
}

type Validation = {
  phone_number?: string;
  access_token?: string;
  refresh_token?: string;
  expires_in: number;
  refresh_expires_in: number;
  error?: string;
}

declare global {
  interface Window { auth0: any; }
}

const Login: React.FC<LoginProps> = ({ auth, dispatch }: LoginProps) => {
  const [creds, setCreds] = useState<Auth | null>(null);
  const [code, setCode] = useState<CodeResponse | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    demoLogin(dispatch, setCreds);
    // eslint-disable-next-line
  }, []);

  // Connect to WS on receive a verification code.
  useEffect(() => {
    if (!code?.code || !creds?.access_token) { return }

    const onValidationReceive = (validation: Validation) => {
      dispatch(loading(true));

      if (validation.error) {
        dispatch(showFlash("Authentication failed!", "error"));
        setCode(null);
        dispatch(loading(false));
      } else {
        const auth: TokenResponse = {
          access_token: validation.access_token as string,
          refresh_token: validation.refresh_token as string,
          expires_in: validation.expires_in,
          refresh_expires_in: validation.refresh_expires_in
        };

        setCode(null);
        dispatch(successfullLogin(auth));
        navigate("/");
        dispatch(loading(false));
      }
    }

    wsConnect(creds.access_token, code.code, onValidationReceive);

  }, [code?.code, creds?.access_token, dispatch, navigate]);

  const showQR = () => {
    if (!creds?.access_token) {
      return;
    }

    dispatch(loading(true));

    const { partner: apiKey } = jwt_decode(creds.access_token as string) as User;

    generateCode(apiKey, { qr: '1', callback_url: `${NUCLEO_API_URL}/api/v1/auth`, response_message: null })
      .then((response) => {
        setCode(response);
        dispatch(loading(false));
      })
      .catch((error) => {
        console.error('Error:', error)
        dispatch(showFlash("Failed to get code!", "error"));
        dispatch(loading(false));
      });
  };

  const authGoogle = async () => {
    const url = "https://staging.whatsauth.me/api/v1/authorize/google";
    //const url = "https://dev-sxiyqdmq1rxfgi80.us.auth0.com/authorize?state=abcd&response_type=code&response_mode=form_post&audience=https://api.whatsauth.me&client_id=OAqeyc3leBFJ7Vg9d0363rzWRV6W2ph1&connection=google-oauth2&scope=openid&redirect_uri=https://staging.whatsauth.me/api/v1/auth";
    try {
      window.location.href = url;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div id="login" className='page-container' >
      <div>
        <div id="welcome">
          <h1>Sign In to WhatsAuth</h1>
          <p>The easiest way to validate your customer's phone number and profile.</p>
        </div>
      </div>
      <div className='login-box'>
        <span className='login-with'>Login with</span>
        {!code ? (
          <div>
            <button onClick={showQR} className='primary'>WhatsAuth</button>
            <button onClick={authGoogle} className='primary'>Google</button>
          </div>
        ) : (
          <div>
            <QR url={code?.qr || ''} />
            <Link href={code?.link} target='_blank' rel='noreferrer'>Click to open WhatsApp</Link>
          </div>
        )}
      </div>
    </div >
  );
};

const demoLogin = (dispatch: React.Dispatch<any>, setCredsFn: React.Dispatch<any>): void => {
  dispatch(loading(true));

  authenticate(user.userName, user.password)
    .then((auth) => {
      setCredsFn(auth);
      subscribetoRefreshToken(auth?.refresh_token, dispatch, setCredsFn);
      dispatch(loading(false));
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(loading(false));
    });
};

const subscribetoRefreshToken = (token: string | undefined, dispatch: React.Dispatch<any>, setCredsFn: React.Dispatch<any>): void => {
  if (!token) { return }

  window.setTimeout(() => {
    refreshAccessToken(token, dispatch, setCredsFn);
  }, 300_000);
}

const refreshAccessToken = (token: string, dispatch: React.Dispatch<any>, setCredsFn: React.Dispatch<any>): void => {
  dispatch(loading(true));

  refreshToken(token)
    .then((auth) => {
      setCredsFn(auth);
      subscribetoRefreshToken(auth?.refresh_token, dispatch, setCredsFn);
      dispatch(loading(false));
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(loading(false));
    });
};

const LoginPage: React.FC = () => {
  return (
    <AppContext.Consumer>
      {({ auth, dispatch }) => (
        <Login auth={auth} dispatch={dispatch} />
      )}
    </AppContext.Consumer>
  );
};

export default LoginPage;
