import { Action } from "../reducers/App";
import { TokenResponse } from "../utils/auth";

export const successfullLogin = (payload: TokenResponse): Action => {
  return {
    type: 'login_success',
    payload: { ...payload, loggedIn: true }
  }
}

export const logout = (): Action => {
  return {
    type: 'logout',
    payload: {}
  }
}

export const loading = (status: boolean): Action => {
  return {
    type: 'loading',
    payload: { loading: status }
  }
}

export const showFlash = (text: string, type: string): Action => {
  return {
    type: 'show_flash',
    payload: { text: text, type: type }
  }
}

export const hideFlash = (): Action => {
  return {
    type: 'hide_flash',
    payload: {}
  }
} 