import { Routes, Route, useNavigate } from "react-router-dom";
import HomePage from '../Home';
import LoginPage from '../Login';
import DemoPage from '../Demo';
import NotFoundPage from '../NotFound';
import ErrorPage from '../Error';
import AuthCodePage from '../AuthCode';
import React, { useContext, useEffect } from "react";
import { AppContext } from "../App/App";
import { loading } from "../../actions";
//import SideMenu from "../../components/side_menu";

interface RedirectorProps {
  path: string;
};

// interface WithMenuProps {
//   element: JSX.Element;
// };

const ERROR_ROUTE = <Route path="/error" element={<ErrorPage />} key="error-page" />;
const DEMO_ROUTE = <Route path="/demo" element={<DemoPage />} key="demo-page" />;
const DEMO_TRS_ROUTE = <Route path="/:lang/demo" element={<DemoPage />} key="demo-page" />;
const QR_DEMO_TRS_ROUTE = <Route path="/:lang/demo/qr" element={<DemoPage />} key="demo-page" />;
const BTN_DEMO_TRS_ROUTE = <Route path="/:lang/demo/btn" element={<DemoPage />} key="demo-page" />;
const QR_DEMO_ROUTE = <Route path="/demo/qr" element={<DemoPage demoType="qr" />} key="qr-demo-page" />;
const BTN_DEMO_ROUTE = <Route path="/demo/btn" element={<DemoPage demoType="btn" />} key="btn-demo-page" />;
const OTP_DEMO_ROUTE = <Route path="/demo/otp/:phone" element={<DemoPage demoType="otp" />} key="btn-demo-page" />;
const AUTH_CODE_ROUTE = <Route path="/code/:code" element={<AuthCodePage />} key="auth-code" />;
const HOME_PATH = <Route path="/" element={<HomePage />} />;

const publicRoutes = [HOME_PATH, ERROR_ROUTE, DEMO_ROUTE, QR_DEMO_ROUTE, BTN_DEMO_ROUTE, OTP_DEMO_ROUTE, AUTH_CODE_ROUTE, DEMO_TRS_ROUTE, QR_DEMO_TRS_ROUTE, BTN_DEMO_TRS_ROUTE];

export const privateRoutes = () => (
  <Routes>
    {publicRoutes}
    <Route path="/login" element={<Redirector path="/" />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export const guestRoutes = () => (
  <Routes>
    {publicRoutes}
    <Route path="/login" element={<LoginPage />} />
    <Route path="*" element={<Redirector path="/login" />} />
  </Routes>
);

// const PageWithSideMenu: React.FC<WithMenuProps> = ({ element }: WithMenuProps): JSX.Element => {
//   const menuItems = [
//     {
//       title: "Dashboard",
//       onClick: () => { }
//     },
//     {
//       title: "Verifier Tool",
//       onClick: () => { }
//     }
//   ];
//   return (
//     <div style={{ display: "flex" }}>
//       <SideMenu items={menuItems} />
//       <div style={{ padding: "20px" }}>{element}</div>
//     </div>
//   );
// };

const Redirector: React.FC<RedirectorProps> = (props: RedirectorProps): JSX.Element => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    dispatch(loading(true));
    navigate(props.path)
    dispatch(loading(false));
  });

  return (
    <div>
      <p>Rederecting...</p>
    </div>
  );
};
