const env = process.env.REACT_APP_ENV;
const nucleoEnv = process.env.REACT_APP_NUCLEO_ENV;
const prefix = nucleoEnv === 'production' ? '' : `${nucleoEnv}.`;
const frontPrefix = nucleoEnv === 'production' ? '' : `-${nucleoEnv}`;

export const KEYCLOAK_URL = 'https://keycloak.letsta.lk';
export const KEYCLOAK_REALM = process.env.REACT_APP_KCREALM;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KCCLIENT;
export const NUCLEO_API_URL = `https://${prefix}whatsauth.me`;
export const NUCLEO_WS_URL = `wss://${prefix}whatsauth.me`;
export const WEB_CLIENT_URL = env === 'development' ? 'http://demo-dev.whatsauth.me:3000' : `https://demo${frontPrefix}.whatsauth.com`;
export const API_KEY = process.env.REACT_APP_API_KEY as string;

export const VALIDATION_OPTIONS = {
  callback_url: `${NUCLEO_API_URL}/api/v1/broadcast`,
  expires_at: 10,
  link_message: 'Verify my phone number with the following code:',
  expiration_message: 'Code is expired.',
  failure_message: 'Somethig went wrong, communication failed.',
  response_message: `Your phone number was successfully verified. Go back to the app ${WEB_CLIENT_URL}`,
  qr: '0'
};