import { User } from "../../types";
import { buildUserFromToken } from "../../utils/helpers";

export type Auth = {
  access_token?: string;
  refresh_token?: string;
  expires_in?: string;
  loggedIn: boolean;
}

type Flash = {
  text: string;
  type: string;
}

type AppState = {
  auth: Auth;
  isLoading: boolean;
  user: User | null;
  flash: Flash | null;
}

export type Action = {
  type: string;
  payload: { [key: string]: any }
}

export const defaultState: AppState = {
  auth: {
    loggedIn: false
  },
  isLoading: false,
  user: null,
  flash: null
};

const appReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'login_success':
      return { ...state, auth: action.payload as Auth, user: buildUserFromToken(action.payload.access_token) };
    case 'loading':
      return { ...state, isLoading: action.payload.loading as boolean }
    case 'logout':
      return { ...state, auth: { loggedIn: false }, user: null }
    case 'show_flash':
      return { ...state, flash: action.payload as Flash }
    case 'hide_flash':
      return { ...state, flash: null }
    default:
      return state;
  }
}

export default appReducer;