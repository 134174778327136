import 'url-search-params-polyfill';
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from '../constants';

type TokenResponseBase = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
}

export interface TokenResponse extends TokenResponseBase {
  [key: string]: string | number;
}

const clientId = KEYCLOAK_CLIENT_ID as string;
const url = `${KEYCLOAK_URL}/auth/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`;

export const user = {
  userName: 'test',
  password: '12341234'
};

export const authenticate = async (userName: string, password: string): Promise<TokenResponse | undefined> => {
  const formData = new URLSearchParams();
  formData.append('grant_type', 'password');
  formData.append('client_id', clientId);
  formData.append('username', userName);
  formData.append('password', password);

  try {
    return await sendTokenRequest(url, formData);
  } catch (error) {
    console.error("TokenError:", error);
  }
}

export const refreshToken = async (token: string): Promise<TokenResponse | undefined> => {
  const formData = new URLSearchParams();
  formData.append('grant_type', 'refresh_token');
  formData.append('client_id', clientId);
  formData.append('refresh_token', token);

  try {
    return await sendTokenRequest(url, formData);
  } catch (error) {
    throw (new Error("Token Error"));
  }
}

const sendTokenRequest = async (url: string, data: URLSearchParams): Promise<TokenResponse | undefined> => {
  try {
    const response = await fetch(url, {
      method: "POST",
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data.toString()
    });

    return toJson(response);
  } catch (error) {
    console.error("TokenError:", error);
  }
}

const toJson = async (response: Response): Promise<TokenResponse> => {
  const json = await response.json();

  if (json.error) {
    throw new Error(`TokenError: ${json.error}`);
  }

  return json as TokenResponse;
}