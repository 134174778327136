import { createGlobalStyle, keyframes } from "styled-components"

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const ldsRing = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline:0;
    box-sizing:border-box;
    font-family: 'Inter';
    background-color: #08061b;
  }

  #root {
    margin:0 auto;
    background-color: #08061b;
    color: #fff;
  }

  .validation-request {
    background-color: black;
  }

  .qr {
    background-color: black;
  }

  #loader {
    .fade-layer {
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: black;
      z-index: 9999;
      opacity: 0.3;
    }

    .lds-ring {
      display: inline-block;
      position: fixed;
      width: 80px;
      height: 80px;
      top: 50%;
      left: 50%;
      margin-top: -40px;
      margin-left: -40px;
      z-index: 99999;
    }
    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #57A361;
      border-radius: 50%;
      animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #57A361 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  .flash {
    width: 100%;
    text-align: center;
    font-size: 20px;
    padding: 10px 10px;

    p {
      display: inline-block;
    }

    span {
      display: inline-block;
      float: right;
      cursor: pointer;
      border: 1px black solid;
      padding: 0px 5px;
    }
  }

  .flash.error {
    background: #bf4c3b75;
  }

  .flash.notice {
    background: #57a3617d;
  }

  header {
    background-color: #08061b;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    padding: 20px 0px;
    padding-left: 6%;

    @media (max-width: 600px) {
      height: 72px;
      padding: 10px 10px;

      img {
        height: 60px;
      }

      .user-corner {
        display: none;
      }
    }

    @media (min-width: 601px) {
      height: 100px;

      img {
        height: 80px;
      }

      .user-corner:hover {
        background-color: white;
        color: #7D7D7D;

        .drop-down {
          display: block;
        }
      }

      .user-corner {
        height: 60px;
        display: table;
        margin-left: auto;
        padding: 10px;
        border-radius: 15px;
        color: white;
        min-width: 250px;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50px;
          border: 2px #57A361 solid;
        }

        span {
          font-size: 20px;
          display: table-cell;
          vertical-align: middle;
          padding-left: 15px;
        }

        .drop-down {
          position: absolute;
          top: 72px;
          right: 20px;
          padding-top: 30px;
          min-width: 250px;
          background: white;
          display: none;

          span {
            cursor: pointer; 
          }
        }
      }
    }
  }

  .transaction {
    padding: 20px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
  }

  .tr-failure {
    background: #BF4C3B;
  }

  .tr-success {
    background: #57A361;
  }

  .json-snippet {
    text-align: left;
    display: inline-block;
    margin: 20px auto;
    background: black;
    padding: 10px;
    padding-left: 10%;
    width: 70%;
    border-radius: 4px;
    animation-name: ${fadeIn};
    animation-duration: 2s;
    animation-iteration-count: 1;
    color: yellow;

    pre {
      background-color: black;
    }

    .lang-json {
      background-color: black;
      p {
        background-color: black;
      }
    }

    .row {
      text-indent: 20px;
      background-color: black;
    }

    .json-key {
      color: white;
    }

    .json-string {
      color: #71D37E;
    }
  }

  .page-container {
    display: flex;

    h1 {
      color: white;
      margin-bottom: 5px;
      font-weight: 700;
    }

    h2 {
      margin-top: 0px;
      color: #7D7D7D;
      font-weight: 700;
      font-style: italic;
    }

    a {
      text-decoration: none;
      &:focus:hover {
        text-decoration: underline;
      }
    }

    button, .button {
      background: #6239ec;
      border-radius: 15px;
      border: 2px solid #BF4C3B;
      color: #BF4C3B;
      padding: 10px 15px;
      cursor: pointer;
      font-weight: 700;
      font-family: Inter, serif;

      .primary {
        background: #6239ec;
        color: #f9f9f7;
        border-color: #57A361;
        font-weight: 700;

        &:hover {
          background-color: ##6239ec;
          border-color: #71D37E;
          text-decoration: none;
        }
      }

      &:hover {
        background: ##6239ec;
        color: #f9f9f7;
        border-color: #BF4C3B;
        text-decoration: none;
      }
    }

    .primary {
      background: #57A361;
      color: #f9f9f7;
      border-color: #57A361;
      font-weight: 700;

      &:hover {
        background-color: #71D37E;
        border-color: #71D37E;
        text-decoration: none;
      }
    }

    .login-box {
      border: 3px #57A361 solid;
      border-radius: 20px;
      padding: 20px;
      width: 300px;
      margin: 70px auto;

      .login-with {
        position: relative;
        top: -34px;
        background: white;
        padding: 0 10px;
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
      padding: 30px 20px;
      text-align: center;
      font-size: 18px;

      div {
        margin-top: 20px;
      }

      h1 {
        font-size: 30px;
        margin-bottom: 30px;
      }

      h2 {
        font-size: 24px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 30px;
      }

      .login-box {
        .qr {
          display: none;
        }
      }

      .json-snippet {
        font-size: 14px;
        width: 100%;
      }

      #demo-text {
        border: 1px solid #BF4C3B;
        border-radius: 4px;
        padding: 10px;
        text-align: left;

        span {
          color: #BF4C3B;
          line-height: 1.5;
          animation-name: ${fadeIn};
          animation-duration: 2s;
          animation-iteration-count: 1;
        }
      }

      button, .button {
        margin: 0 auto;
        width: 100%;
        font-size: 24px;
        display: block;
      }

      .validation-response {
        display: none;
      }

      .validation-request {
        .qr {
          display: none;
        }
      }

      #demo {
        display: flex;
        flex-direction: column;
      }
    }

    @media (min-width: 601px) and (max-width: 1000px) {
      flex-direction: column;
      padding: 30px 20px;
      text-align: center;
      font-size: 18px;

      #welcome {
        h1 {
          margin-top: 50px;
          font-size: 54px;
        }

        p {
          margin: 50px auto;
          width: 50%;
          font-size: 24px;
        }
      }

      button, .button {
        font-size: 24px;
        display: block;
        margin: 10px auto;
        width: 70%;
      }

      #demo {
        display: flex;
        flex-direction: column;

        #demo-mobile-controls, #mobile-start-demo {
          display: none;
        }

        .demo-content {
          display: flex;
          flex-direction: column;
          margin-top: 50px;
          
          div.demo-block {
            width: 100%;
            margin: 0 auto;
          }

          #demo-text {
            border: 1px solid #BF4C3B;
            border-radius: 4px;
            padding: 10px;
            text-align: left;
            width: 70%;
            margin: 0 auto;
    
            span {
              color: #BF4C3B;
              line-height: 1.5;
              animation-name: ${fadeIn};
              animation-duration: 2s;
              animation-iteration-count: 1;
            }
          }

          .validation-request {
            padding: 10px;
            margin: 0px auto;
            width: 70%;

            animation-name: ${fadeIn};
            animation-duration: 2s;
            animation-iteration-count: 1;

            .qr {
              display: none;
            }
          }
        }
      }
    }

    @media (min-width: 1001px) {
      flex-direction: row;
      padding: 10% 10% 10% 2%;
      text-align: center;
      font-size: 18px;

      .json-snippet {
        width: 100%;
        padding-left: 5px;
        .lang-json {
          background-color: black;
          p {
            background-color: black;
          }
        }
      }

      #welcome {
        h1 {
          margin-top: 50px;
          font-size: 54px;
        }

        p {
          margin: 50px auto;
          width: 50%;
          font-size: 24px;
        }
      }

      button, .button {
        font-size: 24px;
        display: block;
        margin: 10px auto;
        width: 100%;
      }

      #demo {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 0 5%;

        #demo-mobile-controls, #mobile-start-demo {
          display: none;
        }

        .demo-content {
          display: flex;
          flex-direction: column;
          margin-top: 50px;
          padding-top: 20px;
          
          div.demo-block {
            margin: 0 auto;
            width: 100%;
          }

          #demo-text {
            border: 1px solid #BF4C3B;
            border-radius: 4px;
            padding: 10px;
            text-align: left;
            width: 100%;
            margin: 0 auto;
    
            span {
              color: #BF4C3B;
              line-height: 1.5;
              animation-name: ${fadeIn};
              animation-duration: 2s;
              animation-iteration-count: 1;
            }
          }

          .validation-request {
            padding: 10px;
            border: 4px solid #57A361;
            border-radius: 50px;
            margin: 0px auto;

            animation-name: ${fadeIn};
            animation-duration: 2s;
            animation-iteration-count: 1;
          }
        }
      }
    }
  }

   div.qr {
     margin: 20px;
   }
   div.validation-response {
   }
`