import { NUCLEO_WS_URL } from "../constants";

const url = process.env.REACT_APP_NUCLEO_ENV === 'production' ? 'wss://ayx12oqerh.execute-api.us-west-1.amazonaws.com/v1' : `${NUCLEO_WS_URL}/api/v1/ws`;

const wsConnect = (token: string | undefined, code: string, onMessage: (event: any) => void): void => {
  const socket = new WebSocket(`${url}?bt=${token}&code=${code}`);

  window.setTimeout(() => {
    if (socket.readyState !== socket.OPEN) {
      socket.close();
      console.error('WebSocket could not connect and will be closed');
      return;
    }

    socket.addEventListener('open', e => {
      console.log('WebSocket is connected')
    })

    socket.addEventListener('close', (e) => {
      console.log('WebSocket is closed', e);
      if (e.code === 1006) {
        wsConnect(token, code, onMessage);
      }
    });

    socket.addEventListener('error', (e) => {
      console.error('WebSocket is in error', e);
      socket.close();
    });

    socket.addEventListener('message', event => {
      onMessage(JSON.parse(event.data));
      socket.close();
    })
  }, 5_000);
}

export { wsConnect }