import React from 'react';

interface LoaderProps {
}

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  return (
    <div id="loader">
      <div className='fade-layer'></div>
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  );
};

export default Loader;