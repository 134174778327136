import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const AuthCodePage: React.FC = (): JSX.Element => {
  const { code } = useParams();

  useEffect(() => {
    const url = "https://staging.whatsauth.me/api/v1/auth";
    fetch(url, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ code }),
      headers: { "Content-Type": "application/json" },
    });
    // eslint-disable-next-line
  }, []);

  return <div>{code}</div>;
};

export default AuthCodePage;